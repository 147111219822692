import React from "react";
import { withAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import { lighttheme } from "../../Utilities/MUIThemes";

class SignUpButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <Button
                id="signup-button"
                variant="contained"
                disableElevation={true}
                sx={{
                    marginRight: "20px",
                    backgroundColor: "#00FF00",
                    color: lighttheme.palette.black1.main,
                    textTransform: "lowercase",
                    '&:hover': {
                        color: lighttheme.palette.white.main,
                        backgroundColor: lighttheme.palette.black3.main,
                    }
                }}
                onClick={() => this.props.auth0.loginWithRedirect({
                        authorizationParams: {
                            screen_hint: "signup",
                        },
                        scope: 'openid profile email offline_access'
                    })
                }>
                start a free trial
            </Button>
        )
    }
}

export default withAuth0(SignUpButton);