import React from 'react';
import { withRouter } from '../wrappers/withRouter';
import 'react-h5-audio-player/lib/styles.css';
import SongCard from '../cards/SongCard';
import Grid from '@mui/material/Grid';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import SongsTable from '../SongsTable';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { rootUrl } from '../../Utilities/Utils';
import { withAuth0 } from '@auth0/auth0-react';
import { SongService } from '../../Services/SongService';
import AppHeader from '../headers/AppHeader';
import { ThemeProvider } from '@mui/material/styles';
import { Container } from '@mui/material';
import { darktheme } from '../../Utilities/MUIThemes';
import { DefaultLoadingScreen } from '../DefaultLoadingScreen';
import AuthService from '../../Services/AuthService';
import { UserService } from '../../Services/UserService';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import StartNewSongCard from '../cards/StartNewSongCard';
import { SongPageFileDropzoneCard } from '../cards/SongPageFileDropzoneCard';
import SongDraftCard from "../cards/SongDraftCard";
import Alert from '@mui/material/Alert';

const songSortOptions = {
  versionHistory: 'versionHistory',
  priority: 'priority',
  versionNotesUpdated: 'versionNotesUpdated'
};

const songDisplayOptions = {
  cards: 'cards',
  table: 'table'
}

class SongsAppContainer extends React.Component {

  constructor(props) {
    super(props);

    let view;

    const params = new URLSearchParams(this.props.router.location.search);
    const foo = params.get('page');

    if (params.get('view') === songDisplayOptions.cards) {
      view = songDisplayOptions.cards;
    } else if (params.get('view') === songDisplayOptions.table) {
      view = songDisplayOptions.table;
    } else {
      view = songDisplayOptions.cards;
    }

    let page;

    if(params.get('page')) {
      page = (Number(params.get('page')) - 1);
    }

    this.state = {
      // loading app data
      isLoadingData: true,

      // main song data
      songs: {},

      // search bar state
      searchBarText: '',

      // pagination options
      numberOfSongsToLoad: 100,
      currentPage: page || 0,
      totalPagesForPagination: 1,

      // query options
      sortOrderForQuery: 'DESC',
      sortMethod: songSortOptions.versionHistory,
      display: view
    }
  }

  async fetchData(){
    let url = `${rootUrl}/songs?limit=${this.state.numberOfSongsToLoad}&sort=${this.state.sortOrderForQuery}&skip=${this.getSkipNumber()}&sortBy=${this.state.sortMethod}`;

    let token = await AuthService.getTokenSilently(this.props.auth0);

    // get or create the currently logged in user
    let user = await UserService.create({}, token);

    console.log('GET OR CREATE USER:', user);

    let data = await fetch(url,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
      });

    let dataJson = await data.json();

    let totalSongCount = await SongService.getRecordTotalCount(token);

    // @TODO NICK fix this query logic
    let totalPages = Math.floor(totalSongCount.count / this.state.numberOfSongsToLoad + 1);

    this.setState({
      totalPagesForPagination: totalPages,
      songs: dataJson,
	  subscriptionStatus: user.subscription.status,
	  subscriptionNextBillDate: user.subscription.nextBillingDate,
      isLoadingData: false
    });
  }

  getSkipNumber() {
    return this.state.currentPage * this.state.numberOfSongsToLoad
  }

  handlePaginationChange(e, value) {
    e.preventDefault();

    this.props.router.navigate(`/app/?page=${value}`);

    this.setState({
      currentPage: value - 1
    }, this.fetchData);
  }

  /**
   * navigate to the uploads page
   * @param e
   */
  handleUploadPageLinkClick(e) {
    e.preventDefault();

    this.props.router.navigate(`/app/sync`);
  }

  /**
   * creates the main song grid view
   * @returns {Element}
   */
  getSongGridView() {

    /*
      check to see which view we're currently using
      e.g. card view, table view, etc.
     */
    if(this.state.display === songDisplayOptions.cards) {
      /*
        @TODO NICK add back in the functionality to have the URL reflect the current view
        this.props.history.push('?view=cards');
       */

      /*
        if the user has songs uploaded, display their song grid view as normal
       */
      if(this.state.songs.length > 0) {
        return (
            <Grid container
                  spacing={4}
                  direction='row'>

              {this.state.songs.map((song) => {
                if(song.versionHistory.length > 0) {
                  return (
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <SongCard key={song.songTitlePretty} song={song} />
                      </Grid>
                  )
                } else {
                  return (
                      <Grid item xs={12} sm={12} md={6} lg={4}>
                        <SongDraftCard key={song.songTitlePretty} song={song} />
                      </Grid>
                  )}
                })
              }
            </Grid>
        )
      } else {
        /*
          the current user has no songs, create a friendly prompt to start uploading a song
         */
        return (
            <Box textAlign={'center'}>
              <Typography variant='h1'>
			  	Welcome! 🏠
              </Typography>
              <br/>
			  <Typography variant='h2'>
			  	Your journey starts here.
			  </Typography>
			  <br />
              <Typography variant='body1'>
                Upload your bounces to start organizing your catalog <br />
				or hit "start a new song" to create a new song idea.<br />
              </Typography>
              <br/>
            </Box>
        )
      }
    }

    if (this.state.display === songDisplayOptions.table) {
      // this.props.history.push('?view=table');
      return (
          <SongsTable />
      )
    }
  }

  /**
   * @TODO NICK if this starts growing in complexity, add to it's own component since we may want to reuse
   * especially if other pages aka the projects, etc. need pagination as well
   *
   * get the pagination bar to allow users to switch quickly between sets of songs
   * @returns {Element}
   */
  getPaginationView() {
    /*
      if the number of songs we have to display is greater than the number of songs
      we're allowed to display on one page, show the pagination option.
     */
    if(this.state.songs.length > this.state.numberOfSongsToLoad) {
      return (
          <Box>
            <Stack spacing={2} direction='row' justifyContent={'center'}>
              <Pagination
                  onChange={(e, value) => this.handlePaginationChange(e, value)}
                  count={this.getPaginationCount()}/>
            </Stack>
            <br/>
            <br/>
          </Box>
      );
    }
  }

  /**
   * get the header cards.
   * @returns {Element}
   */
  getHeaderTilesView() {
    return (
        <Grid
            container
            spacing={4}
            direction='row'
            marginBottom={4}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <SongPageFileDropzoneCard />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <StartNewSongCard />
          </Grid>
        </Grid>
    );
  }

  /**
   * get the max number of pages possible given the current dataset
   */
  getPaginationCount() {
      return this.state.totalPagesForPagination;
  }

  getSubscriptionAlertBanner() {
	if(this.state.subscriptionStatus === 'PENDING_CANCELLATION') {
		let date = new Date(this.state.subscriptionNextBillDate).toLocaleDateString();

		return (
			<Box marginBottom={4}>
				<Alert variant="filled" severity="warning"> 
					Your subscription is pending cancellation - ending {date}. Thank you for giving us a try, come back anytime! 👋
				</Alert>
			</Box>
		);
	}
  }


  render() {
    if(this.state.isLoadingData === false) {
      return (
          <ThemeProvider theme={darktheme}>
            <CssBaseline />
            <Container
                maxWidth={false}
                sx={{
                  // backgroundColor: darktheme.palette.primary.main,
                  // height: '100vh'
                  paddingRight: 1,
                  paddingLeft: 1
                }}
            >

              <AppHeader></AppHeader>


              <Box sx={{
                display: 'flex'
              }}>
                <Box component='main' sx={{
                  flexGrow: 1,
                  p: 3,
                  [`@media only screen and (min-width: 100px)`]: {
                    marginTop: 0,
                  },
                  [`@media only screen and (min-width: 700px)`]: {
                    marginTop: 2,
                  }}}>

				{this.getSubscriptionAlertBanner()}		

                  {this.getHeaderTilesView()}

                  {this.getPaginationView()}

                  {this.getSongGridView()}
                </Box>
              </Box>

            </Container>
          </ThemeProvider>
      );
    } else {
      this.fetchData();
      // we're not authenticated and we've finished loading anything so they shouldn't be able to see the page.
      return <DefaultLoadingScreen />
    }
  }
};

export default withAuth0(withRouter(SongsAppContainer));

/*
    WIP: sorting buttons

    <Button variant='contained' onClick={(e) => this.setSortOrder(e)}>
      Flip Date Sort
    </Button>

    <Button variant='contained' onClick={(e) => this.setPrioritySort(e)}>
      Priority Sort
    </Button>

    <Button variant='contained' onClick={(e) => this.setDisplayView(e, songDisplayOptions.table)}>
      Table View
    </Button>

     async syncData(){
    let syncResults = await fetch(`${rootUrl}/sync`, {
      headers: {
        Authorization: `Bearer ${AuthService.getToken()}`,
        'Content-Type': 'application/octet-stream'
      }
    });

stopPlayingSong (songId) {
    this.globalAudio.pause();
    this.globalAudio = undefined;
  }

  async playSong (songId) {
    if(this.globalAudio && this.globalAudio.pause) {
      this.globalAudio.pause();
    }

    let data = await fetch(`${rootUrl}/audio/${encodeURIComponent(songId)}`,
        {
          headers: {
            Authorization: `Bearer ${AuthService.getToken()}`
          }
        });

    this.globalAudio = new Audio(`${rootUrl}/audio/${encodeURIComponent(songId)}`);

    this.globalAudio.addEventListener('canplaythrough', (event) => {
      /* the audio is now playable; play it if permissions allow
this.globalAudio.play();
});
}

setSortOrder(e, sortOrder) {
  e.preventDefault();

  if(this.state.sortOrderForQuery === 'DESC') {
    this.setState({
      sortOrderForQuery: 'ASC'
    }, this.fetchData);
  } else {
    this.setState({
      sortOrderForQuery: 'DESC'
    }, this.fetchData);
  }
}

setPrioritySort(e) {
  e.preventDefault();
  this.setState({
    sortMethod: songSortOptions.priority
  }, this.fetchData);
}

setNotesUpdatedSort(e) {
  e.preventDefault();
  this.setState({
    sortMethod: songSortOptions.versionNotesUpdated
  }, this.fetchData);
}

    return syncResults;
  }
setDisplayView(e, value) {
    e.preventDefault();

    this.setState({
      display: value
    });
  }
  handleSearchbarTextUpdate(e, text) {
    e.preventDefault();

    this.setState({
      searchBarText: text
    })
  }
 */