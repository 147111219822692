import BaseService from "./BaseService";
import { rootUrl } from "../Utilities/Utils";

export class PaymentsService extends BaseService {

    static resourceURI = 'payments';
    constructor() {
        super();
    }

    static async createCheckoutSession(token) {
        console.log("SERVICE: PaymentsService - createCheckoutSession");

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/create-checkout-session`, requestOptions);
        let resultsJson = await results.json();

        return resultsJson;
    }

	static async cancelSubscription(token) {
		console.log("SERVICE: PaymentsService - cancelSubscription");

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/cancel-subscription`, requestOptions);
        let resultsJson = await results.json();

        return resultsJson;
	}

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}