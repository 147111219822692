import React, {Component} from "react";
import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import SSLogo from "../../assets/logos/ss-logo-full-white.png";
import { withRouter } from '../wrappers/withRouter';
import AutocompleteSongSearchAll from "../AutocompleteSongSearchAll";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import HomeIcon from "@mui/icons-material/Home";
import AlbumIcon from "@mui/icons-material/Album";
import Stack from "@mui/material/Stack";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import AuthService from "../../Services/AuthService";
import { withAuth0 } from "@auth0/auth0-react";
import AccountBoxIcon from '@mui/icons-material/AccountBox';


class AppHeader extends Component {
    constructor() {
        super();
    }

    handleHomeNavClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app`);
    }

    handleProjectNavClick(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/project/list/`);
    }

    handleSynchronizeButton(e){
        e.preventDefault();

        this.props.router.navigate(`/app/sync`);
    }

    handleProfileButton(e) {
        e.preventDefault();

        this.props.router.navigate(`/app/profile`);
    }

    render() {
        return (
            // <ThemeProvider theme={appHeaderTheme}>
            <Box sx={{
                flexGrow: 1,
                paddingRight: 4,
                paddingLeft: 4,
                paddingTop: 4,
                marginTop: 4,
                marginBottom: 2
            }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box textAlign={"center"}>
                            <Box
                                component="img"
                                sx={{
                                    height: 70,
                                    width: 200,
                                    mr: 2,
                                    flexgrow: 1,
                                    '&:hover': {
                                        cursor: 'pointer',
                                    }
                                }}
                                alt="Sounds and Sights Logo White"
                                src={SSLogo}
                                onClick={() => this.props.router.navigate(`/app`)}
                            />
                        </Box>
                    </Grid>
                {/*<Toolbar sx={{ justifyContent: "space-between"}}>*/}
                    <Grid item xs={12} sm={12} md={6}>
                        <Box marginTop={1}>
                            <AutocompleteSongSearchAll />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={3}>
                        <Box>
                            <List component={Stack} direction="row">
                                <ListItem
                                    disablePadding
                                    key={"Home"}
                                    sx={{
                                        flexDirection: "column"
                                    }}>
                                    <ListItemButton
                                        onClick={(e) => this.handleHomeNavClick(e)}
                                        sx={{
                                            justifyContent: "center",
                                        }}>
                                        <HomeIcon />
                                    </ListItemButton>
                                    <Box sx={{
                                        fontSize: 10,
                                    }}>
                                        Home
                                    </Box>
                                </ListItem>
                                <ListItem
                                    key={"Projects"}
                                    sx={{
                                        flexDirection: "column"
                                    }}
                                    disablePadding>
                                    <ListItemButton
                                        onClick={(e) => this.handleProjectNavClick(e)}
                                        sx={{
                                            justifyContent: "center",
                                        }}>
                                        <AlbumIcon />
                                    </ListItemButton>
                                    <Box sx={{
                                        fontSize: 10,
                                    }}>
                                        Projects
                                    </Box>
                                </ListItem>
                                {/* <ListItem
                                    key={"Synchronize"}
                                    disablePadding
                                    sx={{
                                        flexDirection: "column"
                                    }}>
                                    <ListItemButton
                                        onClick={(e) => this.handleSynchronizeButton(e)}
                                        sx={{
                                            justifyContent: "center",
                                        }}>
                                        <CloudUploadIcon />
                                    </ListItemButton>
                                    <Box sx={{
                                        fontSize: 10,
                                    }}>
                                        Upload
                                    </Box>
                                </ListItem> */}
                                <ListItem
                                    key={"Profile"}
                                    disablePadding
                                    sx={{
                                        flexDirection: "column"
                                    }}>
                                    <ListItemButton
                                        onClick={(e) => this.handleProfileButton(e)}
                                        sx={{
                                            justifyContent: "center",
                                        }}>
                                        <AccountBoxIcon />
                                    </ListItemButton>
                                    <Box sx={{
                                        fontSize: 10,
                                    }}>
                                        Profile
                                    </Box>
                                </ListItem>
                                <ListItem key={"Log Out"}
                                          sx={{
                                              flexDirection: "column"
                                          }}
                                          disablePadding>
                                    <ListItemButton
                                        onClick={() => AuthService.logout(this.props.auth0)}
                                        sx={{
                                            justifyContent: "center",
                                        }}>
                                        <MeetingRoomIcon />
                                    </ListItemButton>
                                    <Box sx={{
                                        fontSize: 10,
                                    }}>
                                        Log Out
                                    </Box>
                                </ListItem>
                            </List>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            // </ThemeProvider>
        )
    }
}

export default withAuth0(withRouter(AppHeader));