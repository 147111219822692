import {createTheme} from "@mui/material/styles";

const landingPageBreakpoints = {
    values: {
        xs: 0,
        sm: 850,
        md: 1150,
        lg: 1550
    },
}

const appPageBreakpoints = {
    values: {
        xs: 0,
        sm: 700,
        md: 1150,
        lg: 1550
    },
}

export const lighttheme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#000000'
        },
        white: {
            main: "#FFFFFF"
        },
        green: {
            main: "#00FF00"
        },
        green: {
            main: "#00FF00"
        },
        grey1: {
            main: "#eeeeee"
        },
        grey2: {
            main: "#e8e8e8"
        },
        grey3: {
            main: "#d6d4d4"
        },
        grey4: {
            main: "#777777"
        },
        black1: {
            main: "#000000"
        },
        black2: {
            main: "#222222"
        },
        black3: {
            main: "#292929"
        }
    },
    landingPageBreakpoints,
    typography: {
        h1: {
            fontSize: '6rem',
            fontWeight: 900,
            fontFamily: `'Manrope', 'sans-serif'`,
            [`@media only screen and (min-width: 10px)`]: {
                fontSize: '3.5rem',
                lineHeight: '4rem'
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '5rem',
                lineHeight: '5rem',
            },
            [`@media only screen and (min-width: 700}px)`]: {
                fontSize: '6rem',
                lineHeight: '5rem',
            },
            [`@media only screen and (min-width: 1000px)`]: {
                fontSize: '6rem',
                lineHeight: '6rem',
            },
        },
        h2: {
            fontSize: '4rem',
            fontWeight: 900,
            fontFamily: `'Manrope', 'sans-serif'`,
            [`@media only screen and (min-width: 0px)`]: {
                fontSize: '3rem',
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '4rem',
            },
            [`@media only screen and (min-width: ${landingPageBreakpoints.values.sm}px)`]: {
                fontSize: '4rem',
            },
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 700,
            fontFamily: `'Manrope', 'sans-serif'`,
            [`@media only screen and (min-width: 0px)`]: {
                fontSize: '1rem',
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '1.5rem',
            },
            [`@media only screen and (min-width: ${landingPageBreakpoints.values.sm}px)`]: {
                fontSize: '2rem',
            },
        },
        h4: {
            fontSize: '3rem',
            fontWeight: 500,
            color: "606060",
            fontFamily: `'Manrope', 'sans-serif'`,
            [`@media only screen and (min-width: 0px)`]: {
                fontSize: '1rem',
            },
            [`@media only screen and (min-width: 500px)`]: {
                fontSize: '1.5rem',
            },
            [`@media only screen and (min-width: ${landingPageBreakpoints.values.sm}px)`]: {
                fontSize: '1.5rem',
            },
        },
        p: {
            fontSize: '1rem',
        }
    }
});

export const darktheme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#eeeeee'
        },
        white: {
            main: "#FFFFFF"
        },
        green: {
            main: "#00FF00"
        },
		red: {
			main: "#FF0000"
		},
		orange: {
			main: "#FF9100"
		}
    },
    appPageBreakpoints,
    typography: {
        h1: {
            fontSize: '5rem',
            fontWeight: 900,
            fontFamily: `'Manrope', 'sans-serif'`,
        },
        h2: {
            fontSize: '3rem',
            fontWeight: 700,
            fontFamily: `'Manrope', 'sans-serif'`,
        },
        h3: {
            fontSize: '2rem',
            fontWeight: 500,
            fontFamily: `'Manrope', 'sans-serif'`
        }
    }
});