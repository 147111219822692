import BaseService from "./BaseService";
import { rootUrl } from "../Utilities/Utils";

export class UserService extends BaseService {

    static resourceURI = 'users';
    constructor() {
        super();
    }

    static async getProfile(token) {
        console.log("SERVICE: findAll");

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/`, requestOptions);
        let resultsJson = await results.json();

        return resultsJson;
    }

    static formUpdatePayload(params) {
        return params;
    }

    static formCreatePayload(params) {
        return params;
    }
}