import React, { Component } from 'react';
import { TextField, Button, Container, Typography, CssBaseline, Grid } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { darktheme } from "../../Utilities/MUIThemes";
import AppHeader from "../headers/AppHeader";
import { UserService } from '../../Services/UserService';
import AuthService from '../../Services/AuthService';
import { withAuth0 } from '@auth0/auth0-react';
import { withRouter } from '../wrappers/withRouter';
import { PaymentsService } from '../../Services/PaymentsService';
import PaymentCheckoutPage from './PaymentCheckoutPage';
import Box from '@mui/material/Box';

class ProfilePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            lastName: '',
            subscriptionStatus: '',
            email: '',
            isLoadingData: true
        };
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // Handle form submission logic here
        console.log(this.state);

        // user submit form, update the user profile
    };

	/**
	 * bring the user back to the payment checkout page to create their actual subscription
	 */
	handleUnCancelSubscription(e) {
		this.props.router.navigate(`/app/payments/checkout`);
		return <PaymentCheckoutPage />; // Prevent rendering of other components until redirect
	}

	getSubscriptionCancelButton() {
		if(this.state.subscriptionStatus === 'ACTIVE' || this.state.subscriptionStatus === 'TRIAL') {
			return (
				<Button variant="contained" color="red" fullWidth onClick={(e) => this.handleCancelSubcription(e)}>
					Cancel Subscription
				</Button>
			);
		} else if (this.state.subscriptionStatus === 'PENDING_CANCELLATION') {
			// @TODO NICK add this back in once we know how to handle reactivating a subscription with different statuses e.g. trial, active sub, etc.
			// return (
			// 	<Button variant="contained" color="green" fullWidth onClick={(e) => this.handleUnCancelSubscription(e)}>
			// 		Reactivate Subscription
			// 	</Button>
			// );
		}
	}

    async fetchData() {
        if(this.props.auth0.isAuthenticated) {
            let token = await AuthService.getTokenSilently(this.props.auth0);

            let user = await UserService.getProfile(token);

            this.setState({
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,
                subscriptionStatus: user.subscription.status,
                isLoadingData: false
            });
        } else {
            this.props.router.navigate('/');
        }
    }

	async handleCancelSubcription(event) {
		event.preventDefault();

		let token = await AuthService.getTokenSilently(this.props.auth0);

		// cancel the subscription
		let results = await PaymentsService.cancelSubscription(token);

		// bring the user back to the home page after cancelling their subscription
		// if they still have days of the trial / period they'll be able to use the app 
		// otherwise, will get the boot
		this.props.router.navigate('/app');
	}

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <ThemeProvider theme={darktheme}>
                <CssBaseline />
                <Container maxWidth={false} sx={{
                    paddingRight: 1,
                    paddingLeft: 1
                }}>
                    <AppHeader />
					<Box textAlign={'center'} marginTop={8}>
								<Typography variant='h1'>
								Your profile 👤
								</Typography>
								<br/>
								<Typography variant='h2'>
									Manage your account.
								</Typography>
								<br />
								<Typography variant='body1'>
									More coming here soon. <br />
								</Typography>
								<br/>
							</Box>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        alignContent={"center"}
                        sx={{
                            display: 'flex',
                            marginTop: 0,
                            marginLeft: 1,
                            marginRight: 1
                        }}>
                        <Grid item xs={12} md={4}>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <form onSubmit={this.handleSubmit}>
                                {/* <TextField
                                    label="First Name"
                                    name="firstName"
                                    value={this.state.firstName}
                                    // onChange={this.handleChange}
                                    fullWidth
									inputProps={{ readOnly: true }}
                                    margin="normal"
                                />
                                <TextField
                                    label="Last Name"
                                    name="lastName"
                                    value={this.state.lastName}
                                    // onChange={this.handleChange}
                                    fullWidth
									inputProps={{ readOnly: true }}
                                    margin="normal"
                                /> */}
                                <TextField
                                    label="Subscription Status"
                                    name="subscriptionStatus"
                                    value={this.state.subscriptionStatus}
                                    // onChange={this.handleChange}
                                    fullWidth
									inputProps={{ readOnly: true }}
                                    margin="normal"
                                />
                                <TextField
                                    label="Email"
                                    name="email"
                                    value={this.state.email}
                                    // onChange={this.handleChange}
									inputProps={{ readOnly: true }}
                                    fullWidth
                                    margin="normal"
                                />
                                {/* <TextField
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={this.state.password}
                                    // onChange={this.handleChange}
                                    fullWidth
                                    margin="normal"
                                /> */}
                                {/* <Button type="submit" variant="contained" color="primary" fullWidth>
                                    Save
                                </Button> */}
                            </form>
							<br />
							<br />
							<br />
							{this.getSubscriptionCancelButton()}
                        </Grid>
                        <Grid item xs={12} md={4}>
                        </Grid>
                    </Grid>
                </Container>
            </ThemeProvider>
        );
    }
}

export default withAuth0(withRouter(ProfilePage));