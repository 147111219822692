import { rootUrl } from '../Utilities/Utils';
import AuthService from "./AuthService";

/**
 * base service knows how to do basic crud
 */
class BaseService {

    static resourceURI = ''; // @TODO OVERRIDE

    constructor() {}

    static formUpdatePayload(params) {
        throw new Error("IMPLEMENT THIS");
    }

    static formCreatePayload(params) {
        throw new Error("IMPLEMENT THIS");
    }

    static async update(params, token) {
        console.log("SERVICE: update", params);

        const requestOptions = {
            method: 'PUT',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.formUpdatePayload(params))
        };

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}/${params.id}/`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }
    
    static async create(params, token) {
        console.log("SERVICE: create", params);

        const requestOptions = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(this.formCreatePayload(params))
        };

        console.log("SERVICE: create - request options", requestOptions);

        let resultsRaw = await fetch(`${rootUrl}/${this.resourceURI}`, requestOptions);
        let results = await resultsRaw.json();

        return results;
    }

    static async findOneById(id, token) {
        console.log("SERVICE: findOneById", id);

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/${encodeURIComponent(id)}`, requestOptions);
        let resultsJson = await results.json();

        console.log(resultsJson);

        return resultsJson;
    }

    static async findAll(token) {
        console.log("SERVICE: findAll");

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }

        let results = await fetch(`${rootUrl}/${this.resourceURI}/`, requestOptions);
        let resultsJson = await results.json();

        return resultsJson;
    }

    static async getRecordTotalCount(token) {
        console.log("SERVICE: getRecordTotalCount");

        const requestOptions = {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            }
        }

        let results = await fetch(`${rootUrl}/count/${this.resourceURI}`, requestOptions);
        let resultsJson = await results.json();

        return resultsJson;
    }
}

export default BaseService;

//export default withAuth0(BaseService);