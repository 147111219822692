import React, { Component } from 'react';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PaymentsService } from '../../Services/PaymentsService';
import { withAuth0 } from '@auth0/auth0-react';
import { Box } from "@mui/material";
import AuthService from '../../Services/AuthService';

let stripeKey = process.env.NODE_ENV === 'production' ? "pk_live_51LX85rBCuHMraNh2Egj4fpQVHSk4J5RHx3Aje02Q6h00cVVIY3k9adeNZf9TOL81Gn5jdfglbGuWwrh7uLkVMc4s00xZXI9RuD" : "pk_test_51LX85rBCuHMraNh24WdfmUJj6SzEq5wxaDogEiqZvxx3p0vaLVnx4aIynPIEKAosJiSec5FKUgehjfgNnh8tiykN002JVci4FT";
const stripePromise = loadStripe(stripeKey); // @TODO NICK put this in a utility file

class CheckoutForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientSecret: null,
        };
    }

    async fetchClientSecret() {
        let token = await AuthService.getTokenSilently(this.props.auth0);

        // Create a Checkout Session
        const createCheckoutSessionResponse = await PaymentsService.createCheckoutSession(token);

        console.log("createCheckoutSessionResponse", createCheckoutSessionResponse);
        this.setState({ clientSecret: createCheckoutSessionResponse.clientSecret });
        return createCheckoutSessionResponse.clientSecret;
    }

    render() {
        if(this.props.auth0.isAuthenticated) {
            const options = { fetchClientSecret: this.fetchClientSecret.bind(this) };
            return (               
				<Box>
					<div id="checkout">
						<EmbeddedCheckoutProvider
							stripe={stripePromise}
							options={options}
						>
							<EmbeddedCheckout />
						</EmbeddedCheckoutProvider>
					</div>
				</Box>
            );
        } else {
            return <div>Not Authenticated</div>
        }
    }
}

export default withAuth0(CheckoutForm);